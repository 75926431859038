import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import HomePageLayout from '../../layouts/home'
import SEO from '../../components/seo'
import {
    mimeTypeFromFileExtension,
    absolutePathFromUrl,
} from '../../utils/utils'
import FadeIn from '../../components/animations/FadeIn'
import './home.scss'

export default class HomeTemplate extends Component {
    constructor(props) {
        super(props)
        this.onScrollBound = this.onScroll.bind(this)
        this.state = {
            scrollPosition: 0,
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.onScrollBound)
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollBound)
    }
    onScroll() {
        this.setState({ scrollPosition: window.scrollY })
    }
    render() {
        const page = this.props.data.wordpressPage
        const { featured_video, sections } = page.acf
        const { scrollPosition } = this.state
        const transitionPercentage =
            typeof window !== `undefined`
                ? scrollPosition / window.innerHeight
                : 0
        let videoOverlayOpacity = Math.min(transitionPercentage, 1)
        videoOverlayOpacity = Math.max(videoOverlayOpacity, 0.5)//0.33 original
        let videoScale = 1.0 - transitionPercentage * 0.1
        videoScale = Math.min(videoScale, 1)
        videoScale = Math.max(videoScale, 0.9)
        const transitionPercentageShortened =
            typeof window !== `undefined`
                ? scrollPosition / (window.innerHeight * 0.75)
                : 0
        let titleOpacity = Math.min(transitionPercentageShortened, 1)
        titleOpacity = Math.max(titleOpacity, 0)
        titleOpacity = 1 - titleOpacity
        return (
            <HomePageLayout>
                <SEO
                    yoast={page.yoast}
                    title={page.title}
                    description={page.content}
                    dateModified={page.modified}
                    datePublished={page.date}
                />
                <main className="HomeTemplate">
                    <div className="we-are-closed d-flex flex-column align-items-center justify-content-md-center">
                        <div className="inner d-flex flex-column">
                            <p>Y-Designs was a business born in a bedroom in 2010. We grew along the way and had the opportunity to work with many wonderful clients. Our clients trusted us in helping them build their organizations for the past decade and we are honored.</p>
                            <p>As of this past December, we’ve closed our doors. While we’re sad to move on, we’ve come a long way and are proud of our accomplishments.  Our futures are brighter for it.</p>
                            <p>All of our team members will be moving on to new roles at various places of work or as freelancers. If you’re curious to follow them, click on the links.</p>
                            <p>Thank you for working with us and supporting us. We look forward to working with you in our future endeavors.</p>
                            <br/>
                            <ul className="list-unstyled type-bullets">
                                <li><a href="https://www.linkedin.com/in/ryokokawa/" target="_blank" rel="noopener noreferrer">Ryuhei Yokokawa</a>, Founder</li>
                                <li><a href="https://www.maxtamai.com/" target="_blank" rel="noopener noreferrer">Max Olson</a>, Creative Director</li>
                                <li>Neil Harlow, Chief Engineer</li>
                                <li><a href="https://jenvuong.com/"  target="_blank" rel="noopener noreferrer">Jen Vuong</a>, Designer/Illustrator</li>
                            </ul>
                        </div>
                    </div>
                    <section
                        className="featured-video fixed d-non-mobile"
                        style={{
                            transform: `scale(${videoScale}) translateY(-${scrollPosition /
                                2}px)`,
                        }}
                    >
                        <FadeIn delay={500} config={{ duration: 500 }}>
                            <video muted autoPlay loop playsInline>
                                <source
                                    src={featured_video.localFile.publicURL}
                                    type={mimeTypeFromFileExtension(
                                        featured_video.localFile.ext
                                    )}
                                />
                            </video>
                        </FadeIn>
                        <div
                            className="video-overlay"
                            style={{
                                opacity: videoOverlayOpacity,
                            }}
                            >
                        </div>
                    </section>
                    <section className="heading px-yd d-flex flex-column">
                        <h1
                            className={`d-none`}
                        >
                            {page.acf.home_top_title}
                        </h1>
                        <h2
                            className={`top-title mb-auto d-mobile`}
                            style={{ opacity: titleOpacity }}
                        >
                            {page.acf.home_top_title}
                        </h2>
                        <FadeIn delay={250}>
                            <h2
                                className={`top-title mb-auto d-non-mobile`}
                                style={{ opacity: titleOpacity }}
                            >
                                {page.acf.home_top_title}
                            </h2>
                        </FadeIn>
                    </section>
                    <nav className="top-level-navigation">
                        <ul className={`d-flex list-unstyled m-0 p-0`}>
                            {sections.map((section, index) => (
                                <li
                                    key={index}
                                    className="top-level-link w-100"
                                >
                                    <div className="background-container d-non-mobile">
                                        <Img
                                            critical={true}
                                            fluid={
                                                section.background_image
                                                    .localFile.childImageSharp
                                                    .fluid
                                            }
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                            }}
                                        />
                                    </div>
                                    <Link
                                        to={absolutePathFromUrl(section.link)}
                                        className="px-yd d-flex flex-column justify-content-center h-100"
                                    >
                                        <h2
                                            className={`mb-3`}
                                            dangerouslySetInnerHTML={{
                                                __html: section.title,
                                            }}
                                        />
                                        <div
                                            className={`description type-link-neuehaas mb-5`}
                                        >
                                            {section.description}
                                        </div>
                                        <div className="type-link d-none d-sm-none d-md-none d-lg-block">
                                            {section.link_text}
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </main>
            </HomePageLayout>
        )
    }
}

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            title
            content
            ...YoastPage
            featured_media {
                alt_text
                title
                localFile {
                    publicURL
                }
            }
            acf {
                home_top_title
                featured_video {
                    localFile {
                        ext
                        publicURL
                    }
                }
                sections {
                    title
                    description
                    link
                    link_text
                    background_image {
                        localFile {
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
