import React from 'react'
import PropTypes from 'prop-types'
import Header from '../../components/site-header'
import Footer from '../../components/site-footer'
import LayoutBase from '../LayoutBase'
import FadeIn from '../../components/animations/FadeIn'
import './home-page-layout.scss'

const HomePageLayout = ({ children }) => {
    return (
        <LayoutBase
            className="HomePageLayout"
            theme="dark"
            headerSpacing={false}
        >
            <div className="header-context">
                <div className="d-none d-lg-block">
                    <FadeIn delay={250}>
                        <Header
                            fixed={false}
                            headroomProps={{
                                pinStart:
                                    typeof window !== `undefined`
                                        ? window.innerHeight - 115
                                        : 0,
                            }}
                        />
                    </FadeIn>
                </div>
                <div className="d-none d-md-block d-lg-none">
                    <Header
                        fixed={false}
                        headroomProps={{
                            pinStart:
                                typeof window !== `undefined`
                                    ? window.innerHeight - 96
                                    : 0,
                        }}
                    />
                </div>
                <div className="d-mobile">
                    <Header
                        fixed={true}
                        headroomProps={{
                            pinStart: 0,
                        }}
                    />
                </div>
            </div>
            {children}
            <div className="d-mobile">
                <Footer />
            </div>
        </LayoutBase>
    )
}

HomePageLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default HomePageLayout
