import React from 'react'
import { Spring } from 'react-spring'

export default ({ delay = 0, config = {}, children }) => (
    <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ delay, ...config }}
    >
        {animatedProps => <div style={animatedProps}>{children}</div>}
    </Spring>
)
